import styled from "styled-components";

export const MobileCalendarContainer = styled.div`
font-family: 'MaruBuri-Regular';
height: 100dvh;
width: 90%;
margin-left: 0;
padding: 5dvw;
padding-top: 30px;
background-color: rgba(249, 249, 246, 1);
`